import React from 'react';
import App from 'next/app';
import { isType } from '@nubank/nuds-web/utils/isType/isType';

import WebsiteProvider from '@nubank/www-latam-commons/components/WebsiteProvider/WebsiteProvider';
import printJoinUsMessage from '@nubank/www-latam-commons/utils/asciiart/printJoinUsMessage';
import intlGetCanonicalLocales from '@nubank/www-latam-commons/utils/i18n/intlGetCanonicalLocales';
import intlLocale from '@nubank/www-latam-commons/utils/i18n/intlLocale';
import intlPluralRules from '@nubank/www-latam-commons/utils/i18n/intlPluralRules';
import ImgGlobalStyle from '@nubank/www-latam-commons/styles/ImgGlobalStyles';
import * as experimentsUtils from 'utils/experiments';
import addWebpSupportClass from '@nubank/www-latam-commons/utils/browserFeatures/webp/addWebpSupportClass';
import { getSentryDnsUrl } from '@nubank/www-latam-commons/utils/environmentUtils';

import langMessages from '../locale/es-CO.json';
import { SiteContextProvider } from '../components/SiteContext/SiteContext';
import RouterLink from '../components/RouterLink/RouterLink';
import { errorTracking } from '../utils/error-tracking/errorTracking';

const locale = process.env.NEXT_PUBLIC_WWW_LOCALE;

intlGetCanonicalLocales();
intlLocale();
intlPluralRules();

errorTracking.init({
  dsn: getSentryDnsUrl() || '',
  environment: process.env.NODE_ENV,
});

export default class CustomApp extends App {
  constructor(props, ctx) {
    super(props, ctx);
    printJoinUsMessage('https://international.nubank.com.br/es/carreras/');
  }

  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { pageProps };
  }

  componentDidMount() {
    addWebpSupportClass(document.querySelector('body'));
  }

  render() {
    const { Component, pageProps } = this.props;

    const availableExperiments = Object.values(experimentsUtils)
      .filter(value => isType('object', value))
      .filter(object => Object.keys(object).includes('id') && Object.keys(object).includes('variants'));

    return (
      <WebsiteProvider
        locale={locale}
        messages={langMessages}
        availableExperiments={availableExperiments}
        enablePolyfill={false}
        routerLinkComponent={RouterLink}
      >
        <ImgGlobalStyle />
        <SiteContextProvider>
          <Component {...pageProps} />
        </SiteContextProvider>
      </WebsiteProvider>
    );
  }
}
